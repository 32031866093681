import xw from 'xwind';
import styled from '@emotion/styled';
import { IconButton, styled as muiStyled } from '@mui/material';
import { motion } from 'framer-motion';
import { css } from '@emotion/react';

export const Overlay = styled(motion.div)`
  ${xw`
    fixed inset-0
    flex items-center justify-center
    overflow-x-hidden overflow-y-auto
    outline-none focus:outline-none
  `}
  z-index: 1300;
  background-color: rgba(33, 16, 9, 0.4);
`;

export const ModalContainer = styled(motion.div)(xw`relative w-auto  mx-auto`);

export const CloseButton = muiStyled(IconButton)(
  css([
    xw`absolute outline-none focus:outline-none cursor-pointer text-gray-350 z-20`,
    css`
      top: 1.5rem;
      right: 1.5rem;
      font-size: 20px;
    `,
  ]),
);

export const BackButton = muiStyled(IconButton)(
  css([
    xw`absolute outline-none focus:outline-none cursor-pointer text-gray-350 z-20`,
    css`
      top: 1.5rem;
      left: 1.5rem;
      font-size: 20px;
    `,
  ]),
);
